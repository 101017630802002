import request from '@/utils/request'


// 查询通知消息列表
export function listNotice(query) {
  return request({
    url: '/message/notice/list',
    method: 'get',
    params: query
  })
}

// 查询通知消息分页
export function pageNotice(query) {
  return request({
    url: '/message/notice/page',
    method: 'get',
    params: query
  })
}

// 查询通知消息详细
export function getNotice(data) {
  return request({
    url: '/message/notice/detail',
    method: 'get',
    params: data
  })
}

// 新增通知消息
export function addNotice(data) {
  return request({
    url: '/message/notice/add',
    method: 'post',
    data: data
  })
}

// 修改通知消息
export function updateNotice(data) {
  return request({
    url: '/message/notice/edit',
    method: 'post',
    data: data
  })
}

// 删除通知消息
export function delNotice(data) {
  return request({
    url: '/message/notice/delete',
    method: 'post',
    data: data
  })
}
